import { mapGetters } from 'vuex'
import headerSettings from '../headers-settings'

const ACTION_HEADER = {
  width: '40px',
  value: 'action',
  slot: {
    name: 'header-action'
  },
  align: 'center'
}

export default {
  computed: {
    ...mapGetters(['role']),

    isSupplier() {
      return this.role === this.$constants.users.supplier
    },

    isAdmin() {
      return this.role === this.$constants.users.admin
    },

    headerData() {
      const availableHeaders = this.columns
        .filter(({ enabled }) => enabled)
        .map(item => {
          const { name, source } = item

          return { value: name, text: source, ...(headerSettings[name] || {}) }
        })

      return [
        ...(this.isSupplier ? [] :[ACTION_HEADER]),
        ...availableHeaders
      ]
    }
  }
}
