<template>
  <rcc-row
    :class="classes"
    :headers="columns"
    :row="row"
    v-bind="$attrs"
    v-on-clickaway="away"
    @click="handleRowClick"
  >
    <template #action-cell>
      <div class="action-cell">
        <div v-if="isEditAccess && isReturnsManagementModeEnabled">
          <rcc-checkbox
            ref="checkboxRef"
            :input-value="row.id"
            v-model="selected"
            name="is_cancel"
            class="checkbox"
            @click.native="handleCancelCheckboxClick"
          />
        </div>

        <div v-else-if="isEditAccess && !isReturnsManagementModeEnabled">
          <v-btn
            icon
            small
            :disabled="isEditingEnabled"
            title="Редактирование заявки"
            @click="handleEditClick"
          >
            <v-icon small>
              mdi-lead-pencil
            </v-icon>
          </v-btn>

          <v-btn
            v-if="!row.is_copy"
            icon
            small
            title="Сделать копию заявки"
            :disabled="row.is_robot || row.is_external"
            @click.stop="handleCopyClick"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </div>
      </div>
    </template>

    <template #booking_date-cell="{ value }">
      <div class="date-fact">
        {{ value && $moment(value).format('DD.MM.YYYY') }}
      </div>
    </template>

    <template #booking_date_fact-cell="{ value }">
      <div class="date-fact">
        <rcc-table-inline-date-input
          v-if="isEditingEnabled"
          v-model="form.booking_date_fact"
          :class="bookingDateFactClasses"
        />

        <span v-else>
          {{ value && $moment(value).format('DD.MM.YYYY') }}
        </span>
      </div>
    </template>

    <template #pallets_num_fact-cell="{ value }">
      <div class="pallets-num-fact">
        <rcc-table-inline-number-input
          v-if="isEditingEnabled"
          v-model="form.pallets_num_fact"
          :class="palletsFactClasses"
        />

        <span v-else>
          {{ value }}
        </span>
      </div>
    </template>

    <template #return_point-cell="{ value }">
      <div class="return-point">
        <rcc-table-inline-select
          v-if="isEditingEnabled"
          v-model="form.return_point_id"
          :items="returnPointsForCurrentNetwork"
          show-by="name"
          list-attach
          :is-disabled="row.is_external"
        />

        <span v-else>
          {{ value }}
        </span>
      </div>
    </template>

    <template #store-cell="{ value }">
      <div class="store">
        <rcc-table-inline-select
          v-if="isEditingEnabled"
          v-model="form.store_id"
          show-by="name"
          :items="selectsOptions.stores || []"
          list-attach
        />

        <span v-else>
          {{ value }}
        </span>
      </div>
    </template>

    <template #return_status-cell="{ value }">
      <div class="return-status">
        <rcc-table-inline-select
          v-if="isEditingEnabled"
          v-model="form.return_status_id"
          show-by="name"
          :items="selectsOptions.returnStatuses || []"
          list-attach
          @input="handleReturnStatusInput"
        />

        <span v-else>
          {{ value }}
        </span>
      </div>
    </template>

    <template #status_detail-cell="{ value }">
      <div class="details">
        <rcc-table-inline-select
          v-if="isEditingEnabled"
          v-model="form.status_detail_id"
          show-by="name"
          :items="selectsOptions.details || []"
          list-attach
        />

        <span v-else>
          {{ value }}
        </span>
      </div>
    </template>

    <template #number_1c-cell="{ value }">
      <div class="number-1c">
        <rcc-table-inline-text-input
          v-if="isEditingEnabled"
          v-model="form.number_1c"
        />

        <span v-else>
          {{ value }}
        </span>
      </div>
    </template>

    <template #comment-cell="{ value }">
      <div v-tooltip="row.comment" class="comment">
        <rcc-table-inline-text-input
          v-if="isEditingEnabled"
          v-model="form.comment"
        />

        <span v-else>
          {{ value }}
        </span>
      </div>
    </template>

    <template #row-end>
      <rcc-confirm-dialog
        :is-show.sync="isShowSubmitConfirm"
        title="Вы уверены, что  хотите сохранить изменения?"
        confirm-text="Да"
        cancel-text="Отмена"
        :confirmCallback="submit"
      />

      <rcc-confirm-dialog
        :is-show.sync="isShowCancelConfirm"
        title="Вы уверены, что хотите сбросить изменения?"
        confirm-text="Сбросить"
        cancel-text="Отмена"
        :confirmCallback="() => $emit('editing-disabled')"
      />

      <rcc-message-dialog
        :is-show.sync="isShowMessageDialog"
        message-component-type="input"
        title="Укажите количество копий"
        type="number"
        :min="1"
        :max="10"
        mask="##"
        save-text="Создать"
        cancel-text="Отмена"
        :save-callback="createCopies"
        :rules="[ value => (value > 0 && value <= 10) || 'Введите число от 1 до 10']"
      />
    </template>
  </rcc-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getReturnPointsList } from '@/api/return-points'
import { mixin as clickaway } from 'vue-clickaway'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'
import plural from 'plural-ru'

import RccRow from 'Components/table/rcc-row'
import RccTableInlineDateInput from 'Components/controls/inline_table/date-input'
import RccTableInlineNumberInput from 'Components/controls/inline_table/number-input'
import RccTableInlineSelect from 'Components/controls/inline_table/select'
import RccTableInlineTextInput from 'Components/controls/inline_table/text-input'
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'
import RccCheckbox from 'Components/controls/rcc-checkbox'
import RccMessageDialog from 'Components/dialogs/rcc-message-dialog'

export default {
  name: 'RccReturnTableRow',

  mixins: [ clickaway, validationMixin ],

  components: {
    RccTableInlineDateInput,
    RccTableInlineNumberInput,
    RccTableInlineSelect,
    RccTableInlineTextInput,
    RccConfirmDialog,
    RccCheckbox,
    RccRow,
    RccMessageDialog
  },

  props: {
    isEditingEnabled: {
      type: Boolean,
      default: false
    },

    selectedReturns: {
      type: Array,
      default: () => []
    },

    row: {
      type: Object,
      required: true,
      default: () => ({})
    },

    selectsOptions: {
      type: Object,
      default: () => ({})
    },

    columns: {
      type: Array,
      default: () => []
    }
  },

  validations: {
    form: {
      booking_date_fact: {
        required: requiredIf(function() {
          return this.hasFactValidation
        })
      },
      pallets_num_fact: {
        required: requiredIf(function() {
          return this.hasFactValidation
        })
      }
    }
  },

  data() {
    return {
      v: false,
      form: {},
      returnPointsForCurrentNetwork: [],
      isShowSubmitConfirm: false,
      isShowCancelConfirm: false,
      isShowMessageDialog: false
    }
  },

  computed: {
    ...mapState(['isTableEditingEnabled', 'isReturnsManagementModeEnabled']),
    ...mapGetters(['role']),

    classes() {
      if (this.isSupplierRole) {
        return 'table-row'
      }

      return [
        'table-row',
        this.row.isAttention && 'is-attention',
        this.isEditingEnabled && 'is-editing',
        !this.isEditingEnabled && this.isTableEditingEnabled && 'is-blocked',
        this.isCancelInProgress && 'is-canceled',
        this.isRowSelected && 'is-selected',
        !this.isEditAccess && 'is-no-editable'
      ]
    },

    isRowSelected() {
      return this.selected.includes(this.row.id)
    },

    isBookingDateValid() {
      return !this.form?.booking_date_fact
        || (
          this.form.booking_date_fact.length === 10
            && this.$moment(this.form.booking_date_fact)._isValid
        )
    },

    hasFactValidation() {
      return  this.form.return_status_id
        && this.selectsOptions.returnStatuses.length > 0
        && this.selectsOptions.returnStatuses.find(({ id }) => id === this.form.return_status_id)?.is_validate
    },

    bookingDateFactClasses() {
      return [
        (
          !this.isBookingDateValid
          || (this.$v.form.booking_date_fact.$dirty && this.$v.form.booking_date_fact.$invalid)
        )
          && 'invalid'
      ]
    },

    palletsFactClasses() {
      return [
        this.$v.form.pallets_num_fact.$dirty && this.$v.form.pallets_num_fact.$invalid && 'invalid'
      ]
    },

    selected: {
      get() {
        return this.selectedReturns
      },

      set(value) {
        this.$emit(
          this.selectedReturns.length < value.length ? 'row-select' : 'row-deselect' ,
          this.row
        )
        this.$emit('update:selected-returns', value)
      }
    },

    isCancelInProgress() {
      return this.row.process_status === this.$constants.cancelStatuses.IN_PROGRESS
    },

    isEditAccess() {
      return this.$route.meta.editAccess.includes(this.role)
    },

    isSupplierRole() {
      return this.role === this.$constants.users.supplier
    }
  },

  watch: {
    isEditingEnabled(isEditing) {
      if (isEditing) {
        this.initForm()
      } else {
        this.formDestruct()
      }
    }
  },

  mounted() {
    this.$v.$touch()
  },

  destroyed() {
    this.formDestruct()
  },

  methods: {
    handleEditClick(event) {
      if (this.isTableEditingEnabled) {
        return
      }

      event.stopPropagation()
      this.$router.push({ name: 'returns-edit', params: { id: this.row.id } })
    },

    initForm() {
      this.getFormData()

      if (this.form.network_id && this.returnPointsForCurrentNetwork.length === 0) {
        getReturnPointsList.bind(this)({ network_id: this.form.network_id })
          .then(({ items }) => {
            this.returnPointsForCurrentNetwork = items
          })
      }

      window.addEventListener('keydown', this.handleWindowKeydown,  true)
      window.addEventListener('mousedown', this.handleWindowMousedown)
    },

    getFormData() {
      const {
        id,
        application_code,
        werk_id,
        booking_date_fact,
        pallets_num_fact,
        network_id,
        region_id,
        return_point_id,
        store_id,
        return_status_id,
        status_detail_id,
        number_1c,
        comment,
        supplier_id
      } = this.row

      this.form = {
        id,
        application_code,
        werk_id,
        booking_date_fact,
        pallets_num_fact,
        network_id,
        region_id,
        return_point_id,
        store_id,
        return_status_id,
        status_detail_id,
        number_1c,
        comment,
        supplier_id
      }
    },

    handleWindowKeydown(event) {
      if (event.key === 'Enter' && !this.isShowCancelConfirm) {
        event.stopPropagation()
        this.$v.$touch()

        const errors = [
          !this.$v.form.booking_date_fact.required && 'Заполните поле "Дата факт"',
          !this.$v.form.pallets_num_fact.required && 'Заполните поле "Количество паллет факт"',
          !this.isBookingDateValid && 'Невалидные данные в поле "Количество паллет факт"'
        ].filter(error => error)

        errors.forEach(error => {
          window.snackbar(error, 'error')
        })

        if (errors.length > 0) {
          return
        }

        this.isShowSubmitConfirm = true
      } else if (event.key === 'Escape' && !this.isShowSubmitConfirm) {
        this.checkChanges()
      }
    },

    away(event) {
      if (this.isEditingEnabled && !event.target.closest('.inline-control') && !this.isShowSubmitConfirm) {
        this.checkChanges()
      }
    },

    formDestruct() {
      window.removeEventListener('keydown', this.handleWindowKeydown,  true)
      window.removeEventListener('mousedown', this.handleWindowMousedown)
    },

    submit() {
      this.$apiMethods.returns.update(this.form.id, this.form)
        .then(() => {
          this.$emit('saved', {
            ...this.form,
            return_point: this.getSelectText(this.returnPointsForCurrentNetwork, this.form.return_point_id),
            store: this.getSelectText(this.selectsOptions.stores, this.form.store_id),
            return_status: this.getSelectText(this.selectsOptions.returnStatuses, this.form.return_status_id),
            status_detail: this.getSelectText(this.selectsOptions.details, this.form.status_detail_id)
          })
          window.snackbar('Заявка успешно изменена', 'info')
        })
    },

    getSelectText(collection, id) {
      return collection?.find(item => item.id === id)?.name
    },

    checkChanges() {
      const keys = Object.keys(this.form)

      const hasChanges = keys.some(key => this.row[key] !== this.form[key])

      if (hasChanges) {
        this.isShowCancelConfirm = true
      } else {
        this.$emit('editing-disabled')
      }
    },

    handleReturnStatusInput() {
      this.$v.$touch()
    },

    handleCancelCheckboxClick(event) {
      event.stopPropagation()
    },

    handleRowClick() {
      if (!this.isEditAccess) {
        return
      }

      if (this.isReturnsManagementModeEnabled) {
        this.$refs.checkboxRef?.$el.click()
        return
      }

      this.$emit('click')
    },

    checkAccess(value) {
      return headers.find(header => header.value === value)?.access?.includes(this.role)
    },

    handleCopyClick() {
      this.isShowMessageDialog = true
    },

    async createCopies(copiesCount) {
      const copies = plural(copiesCount, 'копия', 'копии', 'копий')
      const create = plural(copiesCount, 'Создана', 'Созданы', 'Создано')
      const returnNumber = this.row.id

      try {
        await this.$apiMethods.returns.copy(this.row.id, copiesCount)
        window.snackbar(`${create} ${copiesCount} ${copies} заявки №${returnNumber}`, 'info')
        this.isShowMessageDialog = false
        this.$emit('copied')
      } catch (error) {
        console.error('error')
      } finally {
        return Promise.resolve()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table-row {
  pointer-events: all;
  background: transparent;
  line-height: 1.3;
  cursor: pointer;

  ::v-deep th, ::v-deep td {
    padding: $data-table-header-and-footer-paddings !important;
    height: 31px !important;
    font-size: 13px !important;
    white-space: nowrap;
    box-sizing: border-box;
  }

  th {
    background: $data-table-secondary-bg !important;
  }

  .comment {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.is-attention {
    background: $data-table-row-attention-bg;
  }

  &.is-selected {
    background: $data-table-hovered-bg;
  }

  .date-fact {
    min-width: 105px;
  }

  .return-point,
  .store,
  .return-status,
  .details,
  .number-1c {
    min-width: 150px;
  }

  &.is-editing {
    cursor: default;

    td {
      font-size: 13px !important;
    }
  }

  &.is-blocked {
    cursor: default;

    .edit {
      pointer-events: none;

      ::v-deep .v-ripple__container {
        display: none !important;
      }
    }

  }

  &.is-canceled {
    background: #ededed;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }

  .invalid {
    ::v-deep fieldset {
      border-color: $error !important;
    }
  }

  &.is-no-editable {
    cursor: default;
  }

  .checkbox {
    display: flex;
    align-items: center;
    ::v-deep .rcc-checkbox__checkbox {
      height: 19px;
      width: 19px;
      padding: 0;
    }
  }

  .action-cell {
    width: 50px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
